import React from 'react'
import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import useWeb3Forms from "@web3forms/react";
import { useTranslation } from 'react-i18next'

import useViewport from '../hooks/useViewport'

import HamburgerMenu from './HamburgerMenu'

import Logo from '../assets/img/Common/Logo.svg'

const ContactUs = ({ navigationItem, navigateToItem }) => {
    const { isMobile } = useViewport()
    const { t } = useTranslation()

    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    const { submit: onSubmit } = useWeb3Forms({
        access_key: 'ac966563-683b-41a8-bc9e-deae47504a1b',
        onSuccess: () => {
            reset();
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} data-block={t("contacts")} className='contact-us-wrapper'>
            <input type="hidden" name="access_key" value="ac966563-683b-41a8-bc9e-deae47504a1b" />
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='contact-us-header-block'
            >
                {!isMobile && (
                    <div className='header-logo-wrapper'>
                        <img className='contact-us-logo' src={Logo} alt="" />
                    </div>
                )}
                <h5 className='services-section-header'>{t("contacts")}</h5>
                <HamburgerMenu navigationItem={navigationItem} navigateToItem={navigateToItem} />
            </motion.div>
            <div className='contact-us-form-block'>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='contact-us-input-block'
                >
                    <label className='contact-us-input-label'>{t("name")}*</label>
                    <input
                        placeholder='John'
                        className={`contact-us-input ${errors?.name ? 'error' : ''}`}
                        type="text"
                        {...register("name", { required: true })}
                    />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='contact-us-input-block'
                >
                    <label className='contact-us-input-label'>{t("email")}*</label>
                    <input
                        placeholder='example@gmail.com'
                        className={`contact-us-input ${errors?.email ? 'error' : ''}`}
                        type="text"
                        {...register("email", { required: true, pattern: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g })}
                    />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='contact-us-input-block'
                >
                    <label className='contact-us-input-label'>{t("subject")}*</label>
                    <input
                        placeholder='Project'
                        className={`contact-us-input ${errors?.subject ? 'error' : ''}`}
                        type="text"
                        {...register("subject", { required: true })}
                    />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='contact-us-input-block'
                >
                    <label className='contact-us-input-label'>{t("details")}*</label>
                    <textarea
                        placeholder={`${t("details")}...`}
                        className={`contact-us-input ${errors?.subject ? 'error' : ''}`}
                        type="text"
                        {...register("details", { required: true })}
                    />
                </motion.div>
                <motion.button
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    type="submit"
                    className='contact-us-send-button'
                >
                    {t("send")}
                </motion.button>
            </div>
        </form>
    )
}

export default ContactUs