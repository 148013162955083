import React, { useEffect, useState } from "react";
import i18n from "../i18n";

const AppContext = React.createContext();

export const useAppContext = () => React.useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [language, setLanguage] = useState(null)

    useEffect(() => {
        const lang = localStorage.getItem('lang');

        if (lang && (lang === 'ua' || lang === 'en')) {
            setLanguage(lang);
        } else {
            const userLang = navigator.language || navigator.userLanguage;
            const lang = userLang?.split('-')[0];

            if (lang === 'uk') {
                setLanguage('ua');
                localStorage.setItem('lang', 'ua');
            } else {
                setLanguage('en')
                localStorage.setItem('lang', 'en');
            }
        }
    }, [])

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
            localStorage.setItem('lang', language);
        }
    }, [language])

    const value = {
        language, setLanguage
    };

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
};