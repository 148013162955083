export const calculateVisibility = (top, bottom, windowTop, windowBottom) => {
    const windowHeight = windowBottom - windowTop;

    if (top > windowTop && top < windowBottom && bottom > windowTop && bottom < windowBottom) {
        return 100;
    }

    if (top > windowTop && top < windowBottom) {
        return 100 + ((windowTop - top) / windowHeight) * 100;
    }

    if (bottom > windowTop && bottom < windowBottom) {
        return 100 + ((bottom - windowBottom) / windowHeight) * 100;
    }

    return 0;
};