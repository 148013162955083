import React, { useState } from 'react';
import { MdClose, MdMenu } from 'react-icons/md';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../context/AppContext';

const HamburgerMenu = ({ navigationItem, navigateToItem }) => {
    const [isOpen, setIsOpen] = useState(false);

    const { language, setLanguage } = useAppContext()
    const { t } = useTranslation()

    const NAVIGATION_ITEMS = [t("home"), t("technologies"), t("projectsSection"), t("services"), t("testimonials"), t("contacts")]

    const location = useLocation()

    const isProjectPage = location.pathname.includes('project/')

    const handleChangeNavigation = (name) => {
        navigateToItem(name);
        setIsOpen(false);
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, ease: 'easeInOut' }}
            viewport={{ once: true }}
            className="hamburger-menu-mobile relative"
        >
            <button
                className={'flex w-[40px] h-[40px] justify-center items-center rounded-full text-white border-white hover:text-white hover:border-white hidden lg:block'}
                onClick={() => setIsOpen(!isOpen)}
            >
                <MdMenu size={25} fill='#000000' className={`text-[24px] m-auto`} />
            </button>
            <div className={`${isOpen ? 'block' : 'hidden'} w-[100%] h-[100vh] flex flex-col items-center fixed top-0 left-0 bg-white z-10`}>
                <div className={`w-[100%] top-0 sticky z-10`}>
                    <div className={`p-4 flex justify-end items-center`}>
                        <div className={`flex items-center justify-end gap-9 ${isProjectPage ? '' : 'mt-[11px]'}`}>
                            <button
                                className={`flex items-center w-[40px] h-[40px] flex justify-center items-center rounded-full text-white border-white hover:text-white hover:border-white hidden lg:block`}
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <MdClose size={25} fill='#000000' className={`text-[24px] m-auto `} />
                            </button>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col items-end gap-[40px] w-full p-6'>
                    {NAVIGATION_ITEMS.map((item, index) => (
                        <div
                            key={index}
                            className={`text-[20px] leading-6 ${navigationItem === item ? 'font-medium text-[#000000]' : 'font-normal text-[#969696]'} font-Raleway uppercase`}
                            onClick={() => handleChangeNavigation(item)}
                        >
                            {item}
                        </div>
                    ))}
                    <div
                        className={`text-[20px] leading-6 font-medium text-[#000000] font-Raleway uppercase`}
                        onClick={() => { setLanguage(language === 'ua' ? 'us' : 'ua'); setIsOpen(false) }}
                    >
                        {language === 'ua' ? 'UA' : 'US'}
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default HamburgerMenu;