import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import IvanKahan from "../assets/img/Employees/IvanKahan.jpg";
import DmytroTsyben from "../assets/img/Employees/DmytroTsyben.jpg";
import OleksandrRozhko from "../assets/img/Employees/OleksandrRozhko.jpg";
import OleksandrPortianko from "../assets/img/Employees/OleksandrPortianko.png";
import VasylSkrihunets from "../assets/img/Employees/VasylSkrihunets.jpg";
import VladHetmanenko from "../assets/img/Employees/VladHetmanenko.jpg";
import OleksandrShypulin from "../assets/img/Employees/OleksandrShypulin.jpg";
import PavloAndrushchuk from "../assets/img/Employees/PavloAndrushchuk.jpg";
import DmytroPalonka from "../assets/img/Employees/DmytroPalonka.png";
import ViktoriiaMosiichuk from "../assets/img/Employees/ViktoriiaMosiichuk.jpg";
import OleksandraRoiko from "../assets/img/Employees/OleksandraRoiko.jpg";
import OleksandraTsopa from "../assets/img/Employees/OleksandraTsopa.jpg";

import Python from "../assets/img/Technologies/python.svg";
import NodeJS from "../assets/img/Technologies/nodejs.svg";
import FastAPI from "../assets/img/Technologies/fastapi.svg";
import Django from "../assets/img/Technologies/django.svg";
import DjangoNinja from "../assets/img/Technologies/django-ninja.svg";
import Numpy from "../assets/img/Technologies/numpy.svg";
import Pandas from "../assets/img/Technologies/pandas.svg";
import Tenserflow from "../assets/img/Technologies/tenserflow.svg";
import Keras from "../assets/img/Technologies/keras.svg";
import Threading from "../assets/img/Technologies/threading.svg";
import ReactJS from '../assets/img/Technologies/react.svg'
import Firebase from '../assets/img/Technologies/firebase.svg'
import Netlify from '../assets/img/Technologies/netlify.svg'
import Vercel from '../assets/img/Technologies/vercel.svg'
import NextJS from '../assets/img/Technologies/nextjs.svg'

import WarehouseManagementSystemVerticalMobile from '../assets/img/Projects/WarehouseManagementSystem/WarehouseManagementSystemVerticalMobile.svg'
import WarehouseManagementSystemVertical from '../assets/img/Projects/WarehouseManagementSystem/WarehouseManagementSystemVertical.svg'
import WarehouseManagementSystemMobile from '../assets/img/Projects/WarehouseManagementSystem/WarehouseManagementSystemMobile.svg'
import OurPublicRecordsVerticalMobile from '../assets/img/Projects/OurPublicRecords/OurPublicRecordsVerticalMobile.svg'
import WarehouseManagementSystem from '../assets/img/Projects/WarehouseManagementSystem/WarehouseManagementSystem.svg'
import QRCodeveloperVerticalMobile from '../assets/img/Projects/QRCodeveloper/QRCodeveloperVerticalMobile.svg'
import OurPublicRecordsVertical from '../assets/img/Projects/OurPublicRecords/OurPublicRecordsVertical.svg'
import SearchAlertVerticalMobile from '../assets/img/Projects/SearchAlert/SearchAlertVerticalMobile.svg'
import OurPublicRecordsMobile from '../assets/img/Projects/OurPublicRecords/OurPublicRecordsMobile.svg'
import CloudOrderVerticalMobile from '../assets/img/Projects/CloudOrder/CloudOrderVerticalMobile.svg'
import AIExcelBotVerticalMobile from '../assets/img/Projects/AIExcelBot/AIExcelBotVerticalMobile.svg'
import QRCodeveloperVertical from '../assets/img/Projects/QRCodeveloper/QRCodeveloperVertical.svg'
import SmartSurfVerticalMobile from '../assets/img/Projects/SmartSurf/SmartSurfVerticalMobile.svg'
import EconnoteVerticalMobile from '../assets/img/Projects/Econnote/EconnoteVerticalMobile.svg'
import SummarieVerticalMobile from '../assets/img/Projects/Summarie/SummarieVerticalMobile.svg'
import QRCodeveloperMobile from '../assets/img/Projects/QRCodeveloper/QRCodeveloperMobile.svg'
import MusOpenVerticalMobile from '../assets/img/Projects/MusOpen/MusOpenVerticalMobile.svg'
import SearchAlertVertical from '../assets/img/Projects/SearchAlert/SearchAlertVertical.svg'
import OurPublicRecords from '../assets/img/Projects/OurPublicRecords/OurPublicRecords.svg'
import CloudOrderVertical from '../assets/img/Projects/CloudOrder/CloudOrderVertical.svg'
import AIExcelBotVertical from '../assets/img/Projects/AIExcelBot/AIExcelBotVertical.svg'
import SearchAlertMobile from '../assets/img/Projects/SearchAlert/SearchAlertMobile.svg'
import GoogleCloudPlatform from '../assets/img/Technologies/google-cloud-platform.svg'
import SmartSurfVertical from '../assets/img/Projects/SmartSurf/SmartSurfVertical.svg'
import CloudOrderMobile from '../assets/img/Projects/CloudOrder/CloudOrderMobile.svg'
import AIExcelBotMobile from '../assets/img/Projects/AIExcelBot/AIExcelBotMobile.svg'
import EconnoteVertical from '../assets/img/Projects/Econnote/EconnoteVertical.svg'
import SecurityCompliance from '../assets/img/Technologies/security-compliance.svg'
import SummarieVertical from '../assets/img/Projects/Summarie/SummarieVertical.svg'
import QRCodeveloper from '../assets/img/Projects/QRCodeveloper/QRCodeveloper.svg'
import AmazonWebServices from '../assets/img/Technologies/amazon-web-services.svg'
import SmartSurfMobile from '../assets/img/Projects/SmartSurf/SmartSurfMobile.svg'
import MusOpenVertical from '../assets/img/Projects/MusOpen/MusOpenVertical.svg'
import EconnoteMobile from '../assets/img/Projects/Econnote/EconnoteMobile.svg'
import SummarieMobile from '../assets/img/Projects/Summarie/SummarieMobile.svg'
import MusOpenMobile from '../assets/img/Projects/MusOpen/MusOpenMobile.svg'
import SearchAlert from '../assets/img/Projects/SearchAlert/SearchAlert.svg'
import MicrosoftAzure from '../assets/img/Technologies/microsoft-azure.svg'
import AIExcelBot from '../assets/img/Projects/AIExcelBot/AIExcelBot.svg'
import CloudOrder from '../assets/img/Projects/CloudOrder/CloudOrder.svg'
import SmartSurf from '../assets/img/Projects/SmartSurf/SmartSurf.svg'
import Econnote from '../assets/img/Projects/Econnote/Econnote.svg'
import Summarie from '../assets/img/Projects/Summarie/Summarie.svg'
import Kubernetes from '../assets/img/Technologies/kubernetes.svg'
import Serverless from '../assets/img/Technologies/serverless.svg'
import MusOpen from '../assets/img/Projects/MusOpen/MusOpen.svg'
import CI_CD from '../assets/img/Technologies/ci-cd.svg'

function useAppState() {
    const { t } = useTranslation()

    const projects = useMemo(() => {
        return [
            {
                id: 1,
                name: 'SearchAlert',
                image: SearchAlert,
                mobileImage: SearchAlertMobile,
                nda: false,
                projectVerticalImage: SearchAlertVertical,
                projectVerticalImageMobile: SearchAlertVerticalMobile,
                metaTitle: 'SearchAlert',
                metaDescription: 'SEO consultant. Search marketing consultancy and enterprise software. An automatically generated and interactive dashboard that shows the management level live.',
                metaKeywords: 'SEO consultant, SearchAlert, SP-Lutsk, marketing consultancy, enterprise software, management level',
                aboutProject: t("searchAlertAboutProject"),
                solvedChallenges: [
                    t("searchAlertSolvedChallenges")
                ],
                technologies: [
                    {
                        "id": 1,
                        "name": "React",
                        "left_image": null,
                        "right_image": ReactJS,
                        "usage": t("reactTechnologyDescription")
                    },
                    {
                        "id": 2,
                        "name": "Python",
                        "left_image": null,
                        "right_image": Python,
                        "usage": t("pythonTechnologyDescription")
                    },
                    {
                        "id": 3,
                        "name": "MongoDB",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("mongodbTechnologyDescription")
                    },
                    {
                        "id": 4,
                        "name": "SEO",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("seoTechnologyDescription")
                    }
                ],
                testimonials: null,
            },
            {
                id: 2,
                name: 'QRCodeveloper',
                image: QRCodeveloper,
                mobileImage: QRCodeveloperMobile,
                nda: false,
                projectVerticalImage: QRCodeveloperVertical,
                projectVerticalImageMobile: QRCodeveloperVerticalMobile,
                metaTitle: 'QRCodeveloper',
                metaDescription: 'Cutting-edge QR code solutions tailored for businesses, events, and marketing campaigns. Generate, track, and manage QR codes seamlessly with our intuitive platform.',
                metaKeywords: 'QRCodeveloper, QR codes, business solutions, event management, marketing campaigns, QR code generator',
                aboutProject: t("qrCodeDeveloperAboutProject"),
                solvedChallenges: [
                    t("qrCodeDeveloperSolvedChallenges")
                ],
                technologies: [
                    {
                        id: 1,
                        name: "React",
                        left_image: null,
                        right_image: ReactJS,
                        usage: t("reactTechnologyDescription")
                    },
                    {
                        id: 2,
                        name: "Python",
                        left_image: null,
                        right_image: Python,
                        usage: t("pythonTechnologyDescription")
                    },
                    {
                        id: 3,
                        name: "MongoDB",
                        left_image: null,
                        right_image: null,
                        usage: t("mongodbTechnologyDescription")
                    }
                ],
                testimonials: null,
            },
            {
                id: 3,
                name: 'OurPublicRecords',
                image: OurPublicRecords,
                mobileImage: OurPublicRecordsMobile,
                nda: false,
                projectVerticalImage: OurPublicRecordsVertical,
                projectVerticalImageMobile: OurPublicRecordsVerticalMobile,
                metaTitle: 'OurPublicRecords',
                metaDescription: 'Free to access huge database of names and genealogy information, along with history and statistics. Start learning about names. ',
                metaKeywords: 'OurPublicRecords, SP-Lutsk, database of names, genealogy information',
                aboutProject: t("oprAboutProject"),
                solvedChallenges: [
                    t("oprSolvedChallenges")
                ],
                technologies: [
                    {
                        "id": 1,
                        "name": "React",
                        "left_image": null,
                        "right_image": ReactJS,
                        "usage": t("reactTechnologyDescription")
                    },
                    {
                        "id": 2,
                        "name": "Python",
                        "left_image": null,
                        "right_image": Python,
                        "usage": t("pythonTechnologyDescription")
                    },
                    {
                        "id": 3,
                        "name": "MongoDB",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("mongodbTechnologyDescription")
                    },
                    {
                        "id": 4,
                        "name": "SEO",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("oprSeoTechnologyDescription")
                    }
                ],
                testimonials: null,
            },
            {
                id: 4,
                name: 'Econnote',
                image: Econnote,
                mobileImage: EconnoteMobile,
                nda: false,
                projectVerticalImage: EconnoteVertical,
                projectVerticalImageMobile: EconnoteVerticalMobile,
                metaTitle: 'Econnote',
                metaDescription: 'International shipping API companies implementation. Designing and sourcing customers’ fulfillment solution so that they can focus on growing their business.',
                metaKeywords: 'Omnigrowth, SP-Lutsk, International shipping, API companies, fulfillment solution, growing business',
                aboutProject: t("econnoteAboutProject"),
                solvedChallenges: [
                    t("econnoteSolvedChallenges")
                ],
                technologies: [
                    {
                        "id": 1,
                        "name": "Angular",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("angularTechnologyDescription")
                    },
                    {
                        "id": 2,
                        "name": "Python",
                        "left_image": null,
                        "right_image": Python,
                        "usage": t("pythonTechnologyDescription")
                    },
                    {
                        "id": 3,
                        "name": "Shopify API",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("shopifyApiTechnologyDescription")
                    },
                    {
                        "id": 4,
                        "name": "Fulfillment Providers APIs",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("fulfillmentProvidersApiTechnologyDescription")
                    }
                ],
                testimonials: [
                    {
                        id: 2,
                        name: 'Tony Xu',
                        project: t("internationalShippingPlatform"),
                        text: t("tonyXuTestimonial"),
                    }
                ],
            },
            {
                id: 5,
                name: 'CloudOrder',
                image: CloudOrder,
                mobileImage: CloudOrderMobile,
                nda: false,
                projectVerticalImage: CloudOrderVertical,
                projectVerticalImageMobile: CloudOrderVerticalMobile,
                metaTitle: 'CloudOrder',
                metaDescription: 'Innovative dropshipping platform designed to streamline the process of selling products online without the need for inventory. Bridge between major e-commerce platforms and sellers.',
                metaKeywords: 'CloudOrder, SP-Lutsk, dropshipping platform, e-commerce platforms, Amazon MWS, Shopify',
                aboutProject: t("cloudOrderAboutProject"),
                solvedChallenges: [
                    t("cloudOrderSolvedChallenge1"),
                    t("cloudOrderSolvedChallenge2"),
                    t("cloudOrderSolvedChallenge3")
                ],
                technologies: [
                    {
                        "id": 1,
                        "name": "Python",
                        "left_image": null,
                        "right_image": Python,
                        "usage": t("cloudOrderPythonTechnologyDescription")
                    },
                    {
                        "id": 2,
                        "name": "Stripe API & PayPal API",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("stripeAndPaypalTechnologyDescription")
                    },
                    {
                        "id": 3,
                        "name": "React",
                        "left_image": null,
                        "right_image": ReactJS,
                        "usage": t("cloudOrderReactTechnologyDescription")
                    },
                    {
                        "id": 4,
                        "name": "Amazon SP API",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("cloudOrderAmazonSpApiTechnologyDescription")
                    },
                    {
                        "id": 5,
                        "name": "Shopify API",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("cloudOrderShopifyApiTechnologyDescription")
                    }
                ],
                testimonials: [
                    {
                        "id": 1,
                        "name": "Zhen Chen",
                        "project": "CloudOrder.us",
                        "text": t("zhenChenTestimonial")
                    }
                ]
            },
            {
                id: 6,
                name: 'AI Excel Bot',
                image: AIExcelBot,
                mobileImage: AIExcelBotMobile,
                nda: false,
                projectVerticalImage: AIExcelBotVertical,
                projectVerticalImageMobile: AIExcelBotVerticalMobile,
                metaTitle: 'AI Excel Bot',
                metaDescription: 'Revolutionary AI-powered Excel assistant designed to enhance productivity, accuracy, and efficiency in spreadsheet tasks. Seamlessly automate repetitive tasks, analyze data with advanced algorithms, and unlock new insights.',
                metaKeywords: 'AI Excel Bot, Excel assistant, productivity tool, data analysis, automation, spreadsheet tasks',
                aboutProject: t("aiExcelBotAboutProject"),
                solvedChallenges: [
                    t("aiExcelBotSolvedChallenges")
                ],
                technologies: [
                    {
                        id: 1,
                        name: "Python",
                        left_image: null,
                        right_image: Python,
                        usage: t("aiExcelBotPythonTechnologyDescription")
                    },
                    {
                        id: 2,
                        name: "React",
                        left_image: null,
                        right_image: ReactJS,
                        usage: t("reactTechnologyDescription")
                    },
                    {
                        id: 3,
                        name: "OpenAI API",
                        left_image: null,
                        right_image: null,
                        usage: t("openaiApiTechnologyDescription")
                    },
                    {
                        id: 4,
                        name: "Firebase",
                        left_image: null,
                        right_image: Firebase,
                        usage: t("firebaseTechnologyDescription")
                    },
                ],
                testimonials: null,
            },
            {
                id: 7,
                name: 'Warehouse Management System',
                image: WarehouseManagementSystem,
                mobileImage: WarehouseManagementSystemMobile,
                nda: true,
                projectVerticalImage: WarehouseManagementSystemVertical,
                projectVerticalImageMobile: WarehouseManagementSystemVerticalMobile,
                metaTitle: 'Warehouse Management System',
                metaDescription: 'Cutting-edge software solution for efficient and optimized warehouse operations. Streamline inventory management, improve order fulfillment, and enhance overall productivity with our robust WMS.',
                metaKeywords: 'Warehouse Management System, WMS, inventory management, order fulfillment, warehouse operations, logistics software',
                aboutProject: t("warehouseManagementSystemAboutProject"),
                solvedChallenges: [
                    t("warehouseManagementSystemSolvedChallenges")
                ],
                technologies: [
                    {
                        "id": 1,
                        "name": "React",
                        "left_image": null,
                        "right_image": ReactJS,
                        "usage": t("reactTechnologyDescription")
                    },
                    {
                        "id": 2,
                        "name": "Python",
                        "left_image": null,
                        "right_image": Python,
                        "usage": t("pythonTechnologyDescription")
                    },
                    {
                        "id": 3,
                        "name": "Amazon SP API",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("warehouseManagementSystemAmazonSpApiTechnologyDescription")
                    }
                ],
                testimonials: [
                    {
                        "id": 1,
                        "name": "Zhen Chen",
                        "project": "Warehouse Management System",
                        "text": t("zhenChenTestimonial")
                    }
                ],
            },
            {
                id: 8,
                name: 'Musopen',
                image: MusOpen,
                mobileImage: MusOpenMobile,
                nda: false,
                projectVerticalImage: MusOpenVertical,
                projectVerticalImageMobile: MusOpenVerticalMobile,
                metaTitle: 'Musopen',
                metaDescription: 'Provides an online library of public domain music recordings and sheet music. Raises money to finance classical music recordings which are released into the public domain.',
                metaKeywords: 'Musopen, SP-Lutsk, music, public domain music, sheet music, recordings',
                aboutProject: t("musopenAboutProject"),
                solvedChallenges: [
                    t("musopenSolvedChallenges")
                ],
                technologies: [
                    {
                        "id": 1,
                        "name": "React",
                        "left_image": null,
                        "right_image": ReactJS,
                        "usage": t("reactTechnologyDescription")
                    },
                    {
                        "id": 2,
                        "name": "Python",
                        "left_image": null,
                        "right_image": Python,
                        "usage": t("pythonTechnologyDescription")
                    }
                ],
                testimonials: null,
            },
            {
                id: 9,
                name: 'SmartSurf',
                image: SmartSurf,
                mobileImage: SmartSurfMobile,
                nda: false,
                projectVerticalImage: SmartSurfVertical,
                projectVerticalImageMobile: SmartSurfVerticalMobile,
                metaTitle: 'SmartSurf',
                metaDescription: 'Innovative web application designed to enhance the user experience of browsing the internet. Features include a built-in ad blocker, AI-powered recommendations, and a user-friendly interface. Artificial intelligence and machine learning technologies.',
                metaKeywords: 'SmartSurf, SP-Lutsk, web application, chrome extension, user experience, browsing, tabs, ad blocker, artificial intelligence',
                aboutProject: t("smartSurfAboutProject"),
                solvedChallenges: [
                    t("smartSurfSolvedChallenges1"),
                    t("smartSurfSolvedChallenges2"),
                    t("smartSurfSolvedChallenges3"),
                ],
                technologies: [
                    {
                        "id": 1,
                        "name": "React",
                        "left_image": null,
                        "right_image": ReactJS,
                        "usage": t("reactTechnologyDescription")
                    },
                    {
                        "id": 2,
                        "name": "Python",
                        "left_image": null,
                        "right_image": Python,
                        "usage": t("pythonTechnologyDescription")
                    },
                    {
                        "id": 3,
                        "name": "OpenAI API",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("openaiApiTechnologyDescription")
                    },
                    {
                        "id": 4,
                        "name": "Firebase",
                        "left_image": null,
                        "right_image": Firebase,
                        "usage": t("firebaseTechnologyDescription")
                    }
                ],
                testimonials: null,
            },
            {
                id: 10,
                name: 'Summarie',
                image: Summarie,
                mobileImage: SummarieMobile,
                nda: false,
                projectVerticalImage: SummarieVertical,
                projectVerticalImageMobile: SummarieVerticalMobile,
                metaTitle: 'Summarie',
                metaDescription: 'AI-powered text summarization tool designed to simplify the process of reading and understanding lengthy articles. Seamlessly generate concise summaries of articles, blog posts, and research papers.',
                metaKeywords: 'Summarie, SP-Lutsk, text summarization, AI tool, articles, blog posts, research papers',
                aboutProject: t("summarieAboutProject"),
                solvedChallenges: [
                    t("summarieSolvedChallenges")
                ],
                technologies: [
                    {
                        "id": 1,
                        "name": "React",
                        "left_image": null,
                        "right_image": ReactJS,
                        "usage": t("reactTechnologyDescription")
                    },
                    {
                        "id": 2,
                        "name": "Python",
                        "left_image": null,
                        "right_image": Python,
                        "usage": t("pythonTechnologyDescription")
                    },
                    {
                        "id": 3,
                        "name": "OpenAI API",
                        "left_image": null,
                        "right_image": null,
                        "usage": t("openaiApiTechnologyDescription")
                    },
                    {
                        "id": 4,
                        "name": "Firebase",
                        "left_image": null,
                        "right_image": Firebase,
                        "usage": t("firebaseTechnologyDescription")
                    }
                ],
                testimonials: null,
            }
        ]
    }, [t])

    const services = useMemo(() => {
        return [
            {
                id: 1,
                title: t("softwareDevelopmentService"),
                description: t("softwareDevelopmentServiceDescription")
            },
            {
                id: 2,
                title: t("mobileApplicationDevelopmentService"),
                description: t("mobileApplicationDevelopmentServiceDescription")
            }
        ]
    }, [t])

    const people = useMemo(() => {
        return [
            {
                name: `Oleksandr`,
                surname: `Shypulin`,
                img: OleksandrShypulin,
                uuid: `e4511e82-36bc-11ee-be56-0242ac120002`,
                experience: '4 years',
                roles: [`DevSecOps Engineer`, `Python Backend Developer`, `Project manager`],
                skills: [
                    `Amazon Web Services`,
                    `Google Cloud Platform`,
                    `Python`,
                    `JavaScript`,
                    `C/C++`,
                    `Django + DRF`,
                    `FastAPI`,
                    `Flask`,
                    `Tools`,
                    `Docker`,
                    `Docker Compose`,
                    `Terraform`,
                    `Kubernetes`,
                    `Jenkins`,
                    `Github Actions`,
                    `Gitlab CI/CD`,
                    `Ansible`,
                    `Penetration testing`,
                    `Stripe API`,
                    `Wise API`,
                    `Telegram API`,
                    `PayPal API`,
                    `SQL/noSQL DBs`
                ],
                languages: [{
                    'English': 'Advanced',
                }],
                description: `
            👋 Hi there! I'm a skilled DevSecOps engineer with extensive experience in developing and implementing secure and scalable cloud solutions.
            👨‍💻 I specialize in a wide range of programming languages and frameworks, including Python, C++, JavaScript. I'm also familiar with popular DevSecOps tools like Jenkins, GitLab, Ansible, Docker and Terraform.
            ☁️ As for cloud solutions, I have a good understanding of cloud platforms such as AWS and GCP, and and I'm proficient in using various cloud services, including EC2, S3, Lambda, RDS, and more.
            🔒 With a strong focus on security, I'm skilled in using various security tools, including ZAP, Burp Suite, Nmap, and Metasploit. I have experience in implementing security controls and best practices in cloud environments and developing secure DevOps pipelines.
            🎓 In addition to my technical skills, I hold AWS Certified Cloud Practitioner certification.
            🤝 I'm a collaborative team player who enjoys working with clients to understand their needs and deliver high-quality solutions that meet their requirements. I'm confident that I can help you achieve your goals and would love to discuss your project in more detail.
            Thanks for considering my profile!
            `,
                project: ['International Shipping & Warehouse Management Platform', 'CloudOrder', 'iReviews Shopping Assistant', 'SearchAlert', `QRCodeveloper`]
            },
            {
                name: `Dima`,
                surname: `Tsyben`,
                uuid: `e4511fc2-36bc-11ee-be56-0242ac120002`,
                experience: '2 years',
                roles: [`Python Backend Developer`],
                skills: [
                    `Python`,
                    `Django`,
                    `Django Rest Framework`,
                    `Selenium`,
                    `BeautifulSoup`,
                    `Git`,
                    `Amazon SP-API`,
                    `Shopify`,
                    `Google`,
                    `Facebook`,
                    `Instagram API`,
                    `Docker`,
                    `Docker compose`,
                    `HTML/CSS/JavaScript`,
                    `SQL/noSQL DBs`,
                    `PostgreSQL`,
                    `MongoDB`,
                    `Cloud Services`,
                    `Amazon Web Services`
                ],
                languages: [{
                    'English': 'Intermediate',
                }],
                description: `I'm a Python backend developer with 2 years of experience, specializing in web development. I possess a strong proficiency in utilizing in-demand technologies like Django, Python, Git, and Django Rest Framework to develop resilient and scalable web applications. I have hands-on experience working with a wide range of APIs, including Amazon SP-API, Shopify, Google, Facebook, and Instagram API. This firsthand experience has enabled me to proficiently integrate these APIs into my projects, effectively harnessing their functionalities and data.`,
                project: ['International Shipping & Warehouse Management Platform', 'CloudOrder', 'Establish'],
                img: DmytroTsyben
            },
            {
                name: `Oleksandr`,
                surname: `Rozhko`,
                uuid: `e45120ee-36bc-11ee-be56-0242ac120002`,
                experience: '2 years',
                roles: [`Python Backend Developer`, `Project manager`],
                skills: [
                    `Python`,
                    `Django`,
                    `Django Rest Framework`,
                    `Pandas`,
                    `Aiogram`,
                    `Git`,
                    `Docker`,
                    `Docker compose`,
                    `Amazon SP-API`,
                    `Stripe/PayPal API`,
                    `PostgreSQL`,
                    `Redis`,
                    `DocuSign`,
                    `Cloud Services`,
                    `Amazon Web Services`
                ],
                languages: [{
                    'English': 'Upper-Intermediate',
                }],
                description: `I'm a passionate backend developer with a focus on Python, Django, Django REST Framework (DRF), and Aiogram technologies. With 2 years of commercial experience, I've honed my skills in developing robust and efficient backend solutions. My expertise lies in creating scalable APIs using Django and DRF, and I'm also well-versed in leveraging aiogram for building powerful Telegram bots. I'm dedicated to writing clean, maintainable code and keeping up with the latest industry trends. As a backend developer, I thrive in collaborative environments and enjoy tackling complex challenges to deliver high-quality solutions.`,
                project: ['International Shipping & Warehouse Management Platform', 'CloudOrder'],
                img: OleksandrRozhko
            },
            {
                name: `Oleksandr`,
                surname: `Portianko`,
                uuid: `e451222e-36bc-11ee-be56-0242ac120002`,
                experience: '2 years',
                roles: [`Frontend  Developer`],
                skills: [
                    `Javascript`,
                    `Typescript`,
                    `JQuery`,
                    `React`,
                    `Redux/Redux Toolkit`,
                    `HTML/CSS`,
                    `Bootstrap/Tailwind CSS`,
                    `Firebase`,
                    `Git`,
                    `Docker`,
                    `Docker Compose`,
                    `Paypal`,
                    `Stripe`,
                    `Wise API`
                ],
                languages: [{
                    'English': 'Upper-Intermediate',
                }],
                description: `As a frontend developer with 2 years of experience,
            I primarily focus on React development. I have
            practical experience in commercial development
            projects and successfully completed several large
             projects. With a deep understanding of the
            interface technologies and the desire to ensure
            high quality solutions, I strive to create innovative
            and effective ones web applications that meet business
            needs.`,
                project: ['International Shipping & Warehouse Management Platform', 'CloudOrder', 'SearchAlert', `QRCodeveloper`],
                img: OleksandrPortianko,
            },
            {
                name: `Ivan`,
                surname: `Kahan`,
                uuid: `7c3d48ad-2caf-43a7-8249-9acab855fda5`,
                experience: '1 years',
                roles: [`Frontend  Developer`, `QA engineer`],
                skills: [
                    `JavaScript`,
                    `React`,
                    `React Native`,
                    `Redux/Redux Toolkit`,
                    `HTML/CSS`,
                    `Bootstrap/React Bootstrap`,
                    `Firebase`,
                    `Grafana`,
                    `Grafana K6/K6 Browser`,
                    `InfluxDB/PrometheusDB`,
                    `Git`,
                    `Docker`,
                    `Docker compose`
                ],
                languages: [{
                    'English': 'Intermediate',
                }],
                description: `As an Frontend Developer and QA Engineer with 1 year
            of professional experience, I have a solid foundation in
            JavaScript, React, and related technologies. With
            expertise in Redux, Redux Toolkit, RTK Query, and
            HTML/CSS/SCSS, I am skilled at building responsive
            and user-friendly interfaces.
            Furthermore, I possess a good understanding of Grafana,
            K6, K6 Browser, PrometheusDB, and InfluxDB, which
            allows me to allows me to create different performance
            tests and monitor and analyze performance metrics and
            make data-driven decisions. `,
                project: ['International Shipping & Warehouse Management Platform', 'CloudOrder'],
                img: IvanKahan
            },
            {
                name: `Vasyl`,
                surname: `Skrihunets`,
                uuid: `e45124ea-36bc-11ee-be56-0242ac120002`,
                experience: '4 years',
                roles: [`AQA Tester`],
                skills: [
                    `JavaScript`,
                    `K6`,
                    `Selenium Protractor`,
                    `Cypress`,
                    `Cucumber`,
                    `Jenkins`,
                    `Angular`,
                    `HTML/CSS/SCSS`,
                    `Bootstrap`,
                    `Tools`,
                    `Git`,
                    `Docker`,
                    `Docker compose`,
                    `Grafana`,
                    `Prometheus`,
                    `Loki`,
                    `Locust`
                ],
                languages: [{
                    'English': 'Intermediate',
                }],
                description: `I am an experienced AQA tester with four years of experience. My skills and knowledge enable me to conduct quality assurance for software products in commercial projects. I possess a deep understanding of testing methodologies and tools that ensure reliability and excellent functionality. My goal is to deliver the highest quality software products and contribute to the successful implementation of projects.`,
                project: ['International Shipping & Warehouse Management Platform', 'CloudOrder'],
                img: VasylSkrihunets
            },
            {
                name: `Dmytro`,
                surname: `Palonka`,
                uuid: `e45127d8-36bc-11ee-be56-0242ac120002`,
                experience: '4 years',
                roles: [`Frontend  Developer`],
                skills: [
                    `Javascript`,
                    `Typescript`,
                    `JQuery`,
                    `Angular`,
                    `React`,
                    `Redux/Redux Toolkit`,
                    `HTML/CSS`,
                    `Bootstrap/Tailwind CSS`,
                    `Tools`,
                    `Firebase`,
                    `Git`,
                    `Docker`,
                    `Docker Compose`,
                    `Paypal`,
                    `Stripe`
                ],
                languages: [{
                    'English': 'Intermediate',
                }],
                description: `I am an experienced frontend developer with 4 years of experience, specializing in React development. I focus on creating modern user interfaces with an emphasis on speed, efficiency, and security. I have deep knowledge of React and web standards, as well as skills in graphic design. I use Redux, React Router, and Axios for integrating with services and APIs.`,
                project: ['International Shipping & Warehouse Management Platform', 'CloudOrder', 'iReviews Shopping Assistant'],
                img: DmytroPalonka
            },
            {
                name: `Vlad`,
                surname: `Hetmanenko`,
                uuid: `e4512918-36bc-11ee-be56-0242ac120002`,
                experience: '1 year',
                roles: [`Backend Developer`],
                skills: [
                    `Python`,
                    `Django`,
                    `Django Rest Framework`,
                    `pyTelegramBotAPI`,
                    `HTML/CSS/JS`,
                    `Tools`,
                    `Git`,
                    `Docker`,
                    `Docker compose`,
                    `PostgreSQL`,
                ],
                languages: [{
                    'English': 'Upper-Intermediate',
                }],
                description: `Although my experience in back-end development is currently limited, I am excited about the prospect of developing efficient and scalable web applications using Django and the Django Rest API. I expand my skills to make a meaningful contribution to any project I work on, and I am confident in my ability to quickly learn new technologies for innovative solutions`,
                project: ['International Shipping & Warehouse Management Platform', 'CloudOrder'],
                img: VladHetmanenko
            },
            {
                name: `Pavlo`,
                surname: `Andrushchuk`,
                uuid: `e4512a8a-36bc-11ee-be56-0242ac120002`,
                experience: '1 year',
                roles: [`Backend Developer`],
                skills: [
                    `Python`,
                    `Django`,
                    `Django Rest Framework`,
                    `Tools`,
                    `Git`,
                    `Docker`,
                    `Docker compose`,
                    `OpenAI API`,
                    `Telegram Bot API`,
                    `HTML/CSS/JavaScript`,
                    `SQL/noSQL DBs`,
                    `PostgreSQL`
                ],
                languages: [{
                    English: 'Upper-Intermediate',
                }],
                description: `Despite working as a Backend developer for not so long,
            I have already achieved significant
            accomplishments and gained experience to write efficient,
            scalable code, ensuring high-quality solutions for projects.
            I am always open to enhancing my skills and
            staying up-to-date with the latest trends in the
            development field. I strive to create innovative and
            user-friendly software solutions that contribute to
            improving the lives of users.`,
                project: ['International Shipping & Warehouse Management Platform', 'CloudOrder', 'QRCodeveloper'],
                img: PavloAndrushchuk
            },
            {
                name: `Viktoriia`,
                surname: `Mosiichuk`,
                img: ViktoriiaMosiichuk,
                uuid: 'e8e8445b-1166-4e88-b87c-cf78cf92d17c',
                experience: '1 year',
                roles: [`DevSecOps Engineer`],
                skills: [
                    `Amazon Web Services`,
                    `Python`,
                    `Django`,
                    `Docker`,
                    `Docker Compose`,
                    `Terraform`,
                    `Jenkins`,
                    `Github Actions`
                ],
                languages: [{
                    'English': 'Intermediate',
                }],
                description: `
            👋🏼 Hi there! I am a young DevSecOps professional with significant experience in developing and implementing secure and scalable cloud solutions.
            👩🏽‍💻 My skills are focused on the Python programming language and the Django framework, where I have gained valuable experience in creating and maintaining websites.
            🤍 Additionally, I have experience working with various DevSecOps tools and frameworks such as Jenkins, GitHub, Docker, and Terraform.
            💻 I have experience implementing security controls and following best practices in cloud environments, ensuring secure DevOps pipelines.
            ☁️ In addition to my technical skills, I have strong knowledge of the AWS cloud platform and proficiency in using core services such as EC2, S3, Lambda, and RDS.
            🫶🏼Collaboration is one of my strengths, and I enjoy working with clients to understand their needs and deliver high-quality solutions that meet their requirements.
            Thank you for considering my profile! I am confident that my skills and experience can contribute to the success of your project.
            `,
                project: []
            },
            {
                name: `Oleksandra`,
                surname: `Roiko`,
                uuid: `12347365-50a0-49ee-b461-3fbbb4196d42`,
                experience: '1.5 years in web design and 4 years in graphic design',
                roles: [`Web and Graphic Designer`, `SMM manager`],
                skills: [
                    `Layout Design`,
                    `Visual Design`,
                    `Web Design`,
                    `Packing Design`,
                    `Graphic Design`,
                    `Corporate Identity`,
                    `Electronic invitations`,
                    `Illustration Drawing`,
                    `HTML/CSS/JS`,
                    `Adobe Software`,
                    `Figma`,
                    `Canva`,
                    `CorelD`,
                ],
                languages: [{
                    'English': 'Pre-Intermediate',
                }],
                description: `🖖Hi, thanks for your interest!
            💫I am a web designer who will help to implement your ideas.
            👨‍💻I can gladly help you with the design of your website / landing page / logo / branding / graphic design and UI / UX
            🫶🏼I am also a very punctual and responsible person, so I always do all the work on time and with quality, with attention to detail and customer requirements.
            🩵Glad to help at any time! `,
                project: ['', ''],
                img: OleksandraRoiko
            },
            {
                name: `Oleksandra`,
                surname: `Tsopa`,
                uuid: `df8d75ed-b572-4caf-91ff-6741129acfdb`,
                experience: '1 year',
                roles: [`SMM and translation manager`],
                skills: [
                    `Figma`,
                    `Canva`,
                    `Tools`,
                ],
                languages: [{
                    'English': 'Advanced',
                }],
                description: `Hello!🖖 I am a professional SMM manager who specialises in social media promotion and creating trendy design for content.😎 Despite the fact that I have only a year of experience in SMM, I have achieved great success in this field by creating driving ideas for advertising, content, and everything to make the company popular. To achieve this result, I took many marketing courses, read various books about business and how to get to the top, and listened to interviews with famous people who are the real experts in the field of SMM.📱 I am also a skilled translator with extensive experience in communicating with foreigners.🧑‍💻 I have a very strong desire to learn foreign languages. It took me many years to achieve perfection in communicating with foreign-speaking clients, it is easy for me to catch the essence of the conversation and convey the necessary information in a correct business-like style of language.👌 I am doing my best in my work for the development and prosperity of not only our company, but also various projects of our clients.👻`,
                project: ['', ''],
                img: OleksandraTsopa
            },
        ]
    }, [])

    const technologies = useMemo(() => {
        return {
            backend: [
                {
                    id: 1,
                    name: 'Python',
                    icon: Python,
                },
                {
                    id: 2,
                    name: 'NodeJS',
                    icon: NodeJS,
                },
                {
                    id: 3,
                    name: 'FastAPI',
                    icon: FastAPI,
                },
                {
                    id: 4,
                    name: 'Django',
                    icon: Django,
                },
                {
                    id: 5,
                    name: 'Django Ninja',
                    icon: DjangoNinja,
                },
                {
                    id: 6,
                    name: 'NumPy',
                    icon: Numpy,
                },
                {
                    id: 7,
                    name: 'Pandas',
                    icon: Pandas,
                },
                {
                    id: 8,
                    name: 'Tensorflow',
                    icon: Tenserflow,
                },
                {
                    id: 9,
                    name: 'Keras',
                    icon: Keras,
                },
                {
                    id: 10,
                    name: 'Threading',
                    icon: Threading,
                },
                {
                    id: 11,
                    name: 'Multiprocessing',
                    icon: null,
                },
            ],
            frontend: [
                {
                    id: 1,
                    name: 'React JS',
                    icon: ReactJS,
                },
                {
                    id: 2,
                    name: 'NextJS',
                    icon: NextJS,
                },
                {
                    id: 3,
                    name: 'Firebase',
                    icon: Firebase,
                },
                {
                    id: 4,
                    name: 'Netlify',
                    icon: Netlify,
                },
                {
                    id: 5,
                    name: 'Vercel',
                    icon: Vercel,
                },
                {
                    id: 6,
                    name: 'Redux',
                    icon: ReactJS,
                },
                {
                    id: 7,
                    name: 'Chrome extensions',
                    icon: null,
                },
                {
                    id: 8,
                    name: 'Microsoft Add-Ins',
                    icon: null,
                },
            ],
            devsecops: [
                {
                    'id': 1,
                    'name': 'Amazon Web Services',
                    'icon': AmazonWebServices,
                },
                {
                    'id': 2,
                    'name': 'Google Cloud Platform',
                    'icon': GoogleCloudPlatform,
                },
                {
                    'id': 3,
                    'name': 'Microsoft Azure',
                    'icon': MicrosoftAzure,
                },
                {
                    'id': 4,
                    'name': 'Kubernetes',
                    'icon': Kubernetes,
                },
                {
                    'id': 5,
                    'name': 'CI/CD',
                    'icon': CI_CD,
                },
                {
                    'id': 6,
                    'name': 'Serverless',
                    'icon': Serverless,
                },
                {
                    'id': 7,
                    'name': 'Security Compliance',
                    'icon': SecurityCompliance,
                }
            ]
        }
    }, [])

    const testimonials = useMemo(() => {
        return [
            {
                id: 1,
                name: 'Zhen Chen',
                project: 'CloudOrder.us & Warehouse Management System',
                text: t("zhenChenTestimonial"),
            },
            {
                id: 2,
                name: 'Tony Xu',
                project: t("internationalShippingPlatform"),
                text: t("tonyXuTestimonial"),
            },
        ]
    }, [t])

    return {
        projects,
        people,
        technologies,
        services,
        testimonials
    };
}

export default useAppState;
