import React, { useState } from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";

import { newPdg } from "../utils/generatePdf";

import Toast from "./Toast";

import Attach from "../assets/img/Common/Attach.svg";

const JobForm = () => {
    const [showToast, setShowToast] = useState(false);

    const REQUIRED = "This answer is required";
    const INCORRECT_VALUE = "Incorrect value";
    const ATTACHMENT_REQUIRED = "An attachment is required";

    const {
        watch,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            fullName: "",
            email: "",
            phoneNumber: "",
            address: "",
            dateOfBirth: "",
            academicDegree: "",
            photo: null,
            activity: "",
            hobbies: "",
            experience: "",
            socialMedia: "",
            workPlace: "",
            salary: "",
            cv: null,
        },
    });

    const submitForm = async (data) => {
        const cvFile = data.cv[0];
        const pdfBlob = await newPdg(data);

        const newCvFile = new File([cvFile], data.fullName.replace(' ', '_').toLowerCase() + `_cv.${cvFile?.name?.split('.')[1]}`, { type: cvFile.type });
        const newPdfFile = new File([pdfBlob], data.fullName.replace(' ', '_').toLowerCase() + "_application.pdf", { type: "application/pdf" });

        const formData = new FormData();
        formData.append("file", newCvFile);

        if (newPdfFile) {
            formData.append("file", newPdfFile);
        }

        fetch('https://cv-uploader.sp-lutsk.workers.dev/', {
            method: 'POST',
            body: formData,
        }).then(async (response) => {
            if (response.ok) {
                const requestData = {
                    access_key: "ac966563-683b-41a8-bc9e-deae47504a1b",
                    application: `${data.fullName} sent a job application`,
                }

                fetch("https://api.web3forms.com/submit", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify(requestData)
                });

                reset();
                setShowToast(true);
            }
        })
    }

    return (
        <motion.form
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            viewport={{ once: true }}
            onKeyDown={(e) => {
                e.code === "Enter" && e.preventDefault();
            }}
            className="job-page-content"
        >
            <div className="section-name-container">
                <div className="section-name-header-wrapper">Job Application Form</div>
                <div className="section-name-content-wrapper" style={{ fontSize: "14px" }}>
                    <p style={{ fontSize: "14px" }}>All data provided is confidential and will not be shared with any third parties.</p>
                    <p className="required-quize" style={{ fontSize: "14px" }}>
                        (*) indicates that the question is required
                    </p>
                </div>
            </div>
            <div className={`quize-container${errors.fullName ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    Full Name <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <input className="quize-text-input" type="text" placeholder="Your answer" {...register("fullName", { required: REQUIRED })} />
                    {errors.fullName && <p className="required-error-message">{errors.fullName.message}</p>}
                </div>
            </div>
            <div className={`quize-container${errors.email ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    Email <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <input
                        className="quize-text-input"
                        type="email"
                        placeholder="Your answer"
                        {...register("email", { required: REQUIRED, pattern: { value: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: INCORRECT_VALUE } })}
                    />
                    {errors.email && <p className="required-error-message">{errors.email.message}</p>}
                </div>
            </div>
            <div className={`quize-container${errors.phoneNumber ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    Phone Number <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <input
                        className="quize-text-input"
                        type="tel"
                        placeholder="Your answer"
                        // eslint-disable-next-line
                        {...register("phoneNumber", { required: REQUIRED, pattern: { value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, message: INCORRECT_VALUE } })}
                    />
                    {errors.phoneNumber && <p className="required-error-message">{errors.phoneNumber.message}</p>}
                </div>
            </div>
            <div className={`quize-container${errors.address ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    Address <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <input className="quize-text-input" type="text" placeholder="Your answer" {...register("address", { required: REQUIRED })} />
                    {errors.address && <p className="required-error-message">{errors.address.message}</p>}
                </div>
            </div>
            <div className={`quize-container${errors.dateOfBirth ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    Date of birth <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <input
                        className="quize-date-input"
                        type="date"
                        max={new Date(new Date().setFullYear(new Date().getFullYear() - 16)).toISOString().split("T")[0]}
                        {...register("dateOfBirth", { required: REQUIRED })}
                    />
                    {errors.dateOfBirth && <p className="required-error-message">{errors.dateOfBirth.message}</p>}
                </div>
            </div>
            <div className={`quize-container${errors.academicDegree ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    Academic degree <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <input className="quize-text-input" type="text" placeholder="Your answer" {...register("academicDegree", { required: REQUIRED })} />
                    {errors.academicDegree && <p className="required-error-message">{errors.academicDegree.message}</p>}
                </div>
            </div>
            <div className="quize-container">
                <div className="section-header-wrapper">Upload your photo</div>
                <div className="section-content-wrapper">
                    <div
                        className="quize-file-input-wrapper"
                        onClick={() => {
                            document.querySelector("#quize-photo")?.click();
                        }}
                    >
                        <img className="quize-file-input-img" src={Attach} alt="" />
                        <input className="quize-file-input-value" type="text" readOnly placeholder="Click to select file" value={watch("photo")?.[0]?.name || ""} />
                        <input type="file" id="quize-photo" style={{ display: "none" }} {...register("photo")} />
                    </div>
                </div>
            </div>
            <div className="section-name-container">
                <div className="section-name-header-wrapper">Skills and experience</div>
                <div className="section-name-content-wrapper">Tell us what you`re good at</div>
            </div>
            <div className={`quize-container${errors.activity ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    My field of expertise <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <div className="radio-wrapper">
                        <input className="quize-radio-input" type="radio" value="backend" {...register("activity", { required: REQUIRED })} />
                        <label htmlFor="Backend">Backend</label>
                    </div>
                    <div className="radio-wrapper">
                        <input className="quize-radio-input" type="radio" value="frontend" {...register("activity")} />
                        <label htmlFor="Backend">Frontend</label>
                    </div>
                    <div className="radio-wrapper">
                        <input className="quize-radio-input" type="radio" value="devOps" {...register("activity")} />
                        <label htmlFor="Backend">DevOps</label>
                    </div>
                    <div className="radio-wrapper">
                        <input className="quize-radio-input" type="radio" value="fullStack" {...register("activity")} />
                        <label htmlFor="Backend">Full Stack</label>
                    </div>
                    {errors.activity && <p className="required-error-message">{errors.activity.message}</p>}
                </div>
            </div>
            <div className="quize-container">
                <div className="section-header-wrapper">Hobbies</div>
                <div className="section-content-wrapper">
                    <input className="quize-text-input" type="text" placeholder="Your answer" {...register("hobbies")} />
                </div>
            </div>
            <div className={`quize-container${errors.experience ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    Experience in development <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <div className="radio-wrapper">
                        <input className="quize-radio-input" type="radio" value="1<" {...register("experience", { required: REQUIRED })} />
                        <label htmlFor="Less than a year">Less than a year</label>
                    </div>
                    <div className="radio-wrapper">
                        <input className="quize-radio-input" type="radio" value="1-2" {...register("experience")} />
                        <label htmlFor="Backend">1-2 years</label>
                    </div>
                    <div className="radio-wrapper">
                        <input className="quize-radio-input" type="radio" value="3>" {...register("experience")} />
                        <label htmlFor="3 years and more">3 years and more</label>
                    </div>
                    {errors.experience && <p className="required-error-message">{errors.experience.message}</p>}
                </div>
            </div>
            <div className={`quize-container${errors.socialMedia ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    Link to social media <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <input className="quize-text-input" type="text" placeholder="Your answer" {...register("socialMedia", { required: REQUIRED })} />
                    {errors.socialMedia && <p className="required-error-message">{errors.socialMedia.message}</p>}
                </div>
            </div>
            <div className={`quize-container${errors.workPlace ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    Preffered place to work <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <div className="radio-wrapper">
                        <input className="quize-radio-input" type="radio" value="home" {...register("workPlace", { required: REQUIRED })} />
                        <label htmlFor="Home">Home</label>
                    </div>
                    <div className="radio-wrapper">
                        <input className="quize-radio-input" type="radio" value="office" {...register("workPlace")} />
                        <label htmlFor="Office">Office</label>
                    </div>
                    <div className="radio-wrapper">
                        <input className="quize-radio-input" type="radio" value="any" {...register("workPlace")} />
                        <label htmlFor="Doesn`t matter">Doesn`t matter</label>
                    </div>
                    {errors.workPlace && <p className="required-error-message">{errors.workPlace.message}</p>}
                </div>
            </div>
            <div className={`quize-container${errors.salary ? " required" : ""}`}>
                <div className="section-header-wrapper">
                    Preffered salary in USD <span className="required-quize">*</span>
                </div>
                <div className="section-content-wrapper">
                    <input
                        className="quize-text-input"
                        placeholder="Your answer"
                        {...register("salary", { required: REQUIRED, pattern: { value: /^(\$?\s*\d+(?:\.\d{1,2})?)|(\d+(?:\.\d{1,2})?\s*(?:\$|USD)?)$/, message: INCORRECT_VALUE } })}
                    />
                    {errors.salary && <p className="required-error-message">{errors.salary.message}</p>}
                </div>
            </div>
            <div className="section-name-container">
                <div className="section-name-header-wrapper">СV</div>
                <div className="section-name-content-wrapper">
                    <p style={{ margin: 0 }}>
                        Attach your prepared CV below <span className="required-quize">*</span>
                    </p>
                </div>
            </div>
            <div className={`quize-container${errors.cv ? " required" : ""}`}>
                <div className="section-content-wrapper">
                    <div
                        className="quize-file-input-wrapper"
                        onClick={() => {
                            document.querySelector("#quize-cv")?.click();
                        }}
                    >
                        <img className="quize-file-input-img" src={Attach} alt="" />
                        <input className="quize-file-input-value" type="text" readOnly placeholder="Click to select file" value={watch("cv")?.[0]?.name || ""} />
                        <input
                            type="file"
                            id="quize-cv"
                            style={{ display: "none" }}
                            {...register("cv", {
                                required: ATTACHMENT_REQUIRED,
                            })}
                        />
                    </div>
                    {errors.cv && <p className="required-error-message">{errors.cv.message}</p>}
                </div>
            </div>
            <button className="contact-us-send-button" onClick={handleSubmit(submitForm)}>
                SEND
            </button>
            {showToast && (
                <Toast setShowToast={setShowToast} />
            )}
        </motion.form>
    );
};

export default JobForm;
