import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import useViewport from "../hooks/useViewport";

import HamburgerMenu from "../component/HamburgerMenu";
import Navigation from "../component/Navigation";
import JobForm from "../component/JobForm";

import Logo from "../assets/img/Common/Logo.svg";

import "../assets/style/JobForm.css";

const Job = () => {
    const navigate = useNavigate();

    const { isMobile } = useViewport();

    return (
        <div className="main-wrapper">
            <div className="job-page-container">
                <header className="header-wrapper">
                    <motion.div
                        initial={{ opacity: 0, translateX: isMobile - 100 }}
                        whileInView={{ opacity: 1, translateX: 0 }}
                        transition={{ duration: 1, ease: "easeInOut" }}
                        viewport={{ once: true }}
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/")}
                        className="header-logo-wrapper"
                    >
                        <img className="header-logo-icon" src={Logo} alt="" />
                        <span className="logo-project-text">
                            SP
                            <br />
                            LUTSK
                        </span>
                    </motion.div>
                    <HamburgerMenu navigationItem={"Job-Application"} navigateToItem={(item) => navigate(`/?navigateTo=${item}`)} />
                </header>
                <JobForm />
            </div>
            <Navigation navigationItem={"Job-Application"} navigateToItem={(item) => navigate(`/?navigateTo=${item}`)} />
        </div>
    );
};

export default Job;
