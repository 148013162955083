import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import useAppState from '../state/useAppState'
import useViewport from '../hooks/useViewport'

import ProjectTechnologiesTree from '../component/ProjectTechnologiesTree'
import HamburgerMenu from '../component/HamburgerMenu'
import Navigation from '../component/Navigation'

import ProjectSolvedChallenge from '../assets/img/Common/ProjectSolvedChallenge.svg'

const Project = () => {
    const [readMoreMobile, setReadMoreMobile] = useState(false)
    const [project, setProject] = useState({})

    const { id } = useParams()
    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const solvedChallengesRef = useRef(null)

    const navigate = useNavigate()

    const { projects } = useAppState()

    const aboutProjectColumns = useMemo(() => {
        if (project.aboutProject) {
            const aboutSentences = project?.aboutProject?.split('.').filter((el) => el !== '')

            const half = Math.ceil(aboutSentences.length / 2)
            const firstHalf = aboutSentences.splice(0, half).join('.') + '.'
            const secondHalf = aboutSentences.join('.') + '.'

            return [firstHalf, secondHalf]
        } else {
            return []
        }
    }, [project])

    const handleResizeWindow = useCallback(() => {
        if (project.solvedChallenges && solvedChallengesRef.current && project.solvedChallenges.length > 1) {
            const solvedChallengesLength = project.solvedChallenges.length
            const firstChallengeHeight = solvedChallengesRef.current.children[0].clientHeight
            const secondChallengeItem = solvedChallengesRef.current.children[1]
            let solvedChallengesIcon = null

            if (isMobile && !readMoreMobile) {
                solvedChallengesIcon = solvedChallengesRef.current.children[1]
            } else {
                solvedChallengesIcon = solvedChallengesRef.current.children[solvedChallengesLength]
            }

            solvedChallengesIcon.style.top = `${isMobile ? firstChallengeHeight + 16 : firstChallengeHeight + 40}px`

            if ((isMobile && readMoreMobile) || !isMobile) {
                let solvedIconHeightLeft = isMobile ? 129 - secondChallengeItem.clientHeight : 314 - secondChallengeItem.clientHeight
                secondChallengeItem.style.width = `calc(100% -  ${isMobile ? '150px' : '260px'})`

                for (let i = 2; i < solvedChallengesLength; i++) {
                    const challengeItem = solvedChallengesRef.current.children[i]

                    if (solvedIconHeightLeft > 0) {
                        challengeItem.style.width = `calc(100% - ${isMobile ? '129px' : '320px'})`

                        solvedIconHeightLeft -= challengeItem.clientHeight
                    } else {
                        challengeItem.style.width = '100%'
                    }
                }

                if (solvedIconHeightLeft > 0) {
                    solvedChallengesRef.current.style.paddingBottom = `${solvedIconHeightLeft}px`
                }
            }
        } else if (project.solvedChallenges && solvedChallengesRef.current && project.solvedChallenges.length === 1) {
            const solvedChallengesLength = project.solvedChallenges.length
            const firstChallenge = solvedChallengesRef.current.children[0]
            let solvedChallengesIcon = null

            if (isMobile && !readMoreMobile) {
                solvedChallengesIcon = solvedChallengesRef.current.children[1]
            } else {
                solvedChallengesIcon = solvedChallengesRef.current.children[solvedChallengesLength]
            }

            solvedChallengesIcon.style.top = `0px`

            firstChallenge.style.width = `calc(100% - ${isMobile ? '150px' : '300px'})`
        }
    }, [project, isMobile, readMoreMobile])

    useEffect(() => {
        handleResizeWindow()
        window.addEventListener('resize', handleResizeWindow)

        return () => window.removeEventListener('resize', handleResizeWindow)
    }, [handleResizeWindow, project])

    useEffect(() => {
        if (id && projects) {
            const resultProject = projects.find((el) => el.id === Number(id))

            if (resultProject) {
                setProject(resultProject)
            } else {
                navigate('/')
            }
        }
    }, [id, projects, navigate])

    return (
        <section className='project-page-wrapper'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{project?.metaTitle}</title>
                <meta name="description" content={project?.metaDescription} />
                <meta name="keywords" content={project?.metaKeywords} />
            </Helmet>
            <div className='project-page-content'>
                <div className='project-page-header'>
                    <Link to='/' >
                        <motion.h3
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 1, ease: 'easeInOut' }}
                            viewport={{ once: true }}
                            className='project-content-title'
                        >
                            {t("projectsSection")}
                        </motion.h3>
                    </Link>
                    <HamburgerMenu navigationItem={""} navigateToItem={(item) => navigate(`/?navigateTo=${item}`)} />
                </div>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='project-content-header'
                >
                    {isMobile ? (
                        <img width={"100%"} src={project?.projectVerticalImageMobile} alt="" />
                    ) : (
                        <img width={"100%"} src={project?.projectVerticalImage} alt="" />
                    )}
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className={`project-content-about-project ${aboutProjectColumns[1] === '.' ? 'full-width' : ''}`}
                >
                    {aboutProjectColumns[1] !== '.' ? (
                        <>
                            {aboutProjectColumns.map((column, index) => (
                                <p key={index} className='project-content-about-column'>
                                    {column}
                                </p>
                            ))}
                        </>
                    ) : (
                        <p className='project-content-about-column'>
                            {project?.aboutProject}
                        </p>
                    )}
                </motion.div>
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} viewport={{ once: true }} className='mobile-section-underline'></motion.div>
                <div className={`project-solved-challenges-block ${isMobile && !readMoreMobile ? 'short-text' : ''} ${project?.solvedChallenges?.length < 2 ? 'without-padding' : ''}`}>
                    <motion.h6
                        className='solved-challenges-block-title'
                    >
                        {t("solvedChallenges")}
                    </motion.h6>
                    <div ref={solvedChallengesRef} className={`solved-challenges-list ${project?.solvedChallenges?.length === 1 ? 'min-height-image' : ''}`}>
                        {project?.solvedChallenges?.map((challenge, index) => {
                            if (isMobile && index >= 1 && !readMoreMobile) return null

                            return (
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ duration: 1, ease: 'easeInOut' }}
                                    viewport={{ once: true }}
                                    key={index}
                                    className='solved-challenge-item'
                                >
                                    {challenge}
                                </motion.p>
                            )
                        })}
                        <motion.img
                            initial={{ opacity: 0, translateX: isMobile ? 0 : 100 }}
                            whileInView={{ opacity: 1, translateX: 0 }}
                            transition={{ duration: 1, ease: 'easeInOut' }}
                            viewport={{ once: true }}
                            className='solved-challenges-icon'
                            src={ProjectSolvedChallenge}
                            alt=""
                        />
                        {!readMoreMobile && project?.solvedChallenges?.length > 1 && (
                            <span onClick={() => setReadMoreMobile(true)} className='mobile-solved-challenges-read-more'>Read more...</span>
                        )}
                    </div>
                </div>
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} viewport={{ once: true }} className='mobile-section-underline'></motion.div>
                <div className='project-technologies-block'>
                    {project?.technologies && (
                        <ProjectTechnologiesTree list={project.technologies} />
                    )}
                </div>
                {project?.testimonials && project?.testimonials.length > 0 && (
                    <>
                        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} viewport={{ once: true }} className='mobile-section-underline'></motion.div>
                        <motion.div
                            initial={{ opacity: 0, translateX: isMobile ? 0 : -100 }}
                            whileInView={{ opacity: 1, translateX: 0 }}
                            transition={{ duration: 1, ease: 'easeInOut' }}
                            viewport={{ once: true }}
                            className='project-testimonial-list-block'
                        >
                            {project?.testimonials?.map((testimonial) => (
                                <div key={testimonial.id} className='testimonial-block-item'>
                                    <div className='testimonial-block-item-heading'>
                                        <h6 className='testimonial-block-item-name'>{testimonial.name}</h6>
                                        <div className='testimonial-block-item-vertical-line'></div>
                                        <h6 className='testimonial-block-item-name'>{testimonial.project}</h6>
                                    </div>
                                    <p className='testimonial-block-item-text'>
                                        {testimonial.text}
                                    </p>
                                </div>
                            ))}
                        </motion.div>
                    </>
                )}
            </div>
            <Navigation navigationItem={''} navigateToItem={(item) => navigate(`/?navigateTo=${item}`)} />
        </section>
    )
}

export default Project