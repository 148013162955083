import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet-async'

import { calculateVisibility } from '../utils/functions'

import TechnologiesSection from '../component/TechnologiesSection'
import TestimonialsSection from '../component/TestimonialsSection'
import ServicesSection from '../component/ServicesSection'
import ProjectsSection from '../component/ProjectsSection'
import HomeSection from '../component/HomeSection'
import Navigation from '../component/Navigation'
import ContactUs from '../component/ContactUs'

const Main = () => {
    const [navigationItem, setNavigationItem] = useState('Home')
    const [searchParams] = useSearchParams()

    const contentRef = useRef(null)

    const navigateToItem = useCallback((item) => {
        const block = contentRef.current.querySelector(`[data-block="${item}"]`)
        block.scrollIntoView({ behavior: 'smooth' })
    }, [])

    useEffect(() => {
        const navigateTo = searchParams.get('navigateTo')

        if (navigateTo) {
            setTimeout(() => {
                navigateToItem(navigateTo)
                window.history.replaceState({}, document.title, window.location.pathname)
            }, 100)
        }
    }, [searchParams, navigateToItem])

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: [0.05, 0.5, 1],
        };

        const blockElements = Array.from(contentRef?.current?.children || []).filter((element) => !element.classList.contains('main-mobile-section-underline'));

        const observer = new IntersectionObserver(() => {
            const windowTop = window.scrollY;
            const windowBottom = windowTop + window.innerHeight;

            let max = 0;
            let maxElement = null;

            blockElements.forEach((element) => {
                const rect = element.getBoundingClientRect();
                const elementTop = rect.top + window.scrollY;
                const elementBottom = elementTop + element.clientHeight;

                const visibility = calculateVisibility(elementTop, elementBottom, windowTop, windowBottom);

                if (visibility > max) {
                    max = visibility;
                    maxElement = element;
                }
            });
            if (maxElement) {
                const currentBlockName = maxElement.dataset.block;
                setNavigationItem(currentBlockName);
            }
        }, options);

        blockElements.forEach((element) => {
            observer.observe(element);
        });

        return () => {
            observer.disconnect();
        };
    }, [contentRef]);

    return (
        <div className='main-wrapper'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SP-Lutsk</title>
                <meta name="description" content="We provide service of web-projects development for all tastes. With expertise across diverse technologies we offer comprehensive solutions. Include  E-commerce APIs, secure authorization, AWS experts." />
                <meta name="keywords" content="SP-Lutsk, web-projects development, service of web-projects, E-commerce APIs, secure authorization, AWS experts" />
            </Helmet>
            <div ref={contentRef}>
                <HomeSection contentRef={contentRef} navigationItem={navigationItem} navigateToItem={navigateToItem} />
                <TechnologiesSection navigationItem={navigationItem} navigateToItem={navigateToItem} />
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} viewport={{ once: true }} className='main-mobile-section-underline'></motion.div>
                <ProjectsSection navigationItem={navigationItem} navigateToItem={navigateToItem} />
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} viewport={{ once: true }} className='main-mobile-section-underline'></motion.div>
                <ServicesSection navigationItem={navigationItem} navigateToItem={navigateToItem} />
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} viewport={{ once: true }} className='main-mobile-section-underline'></motion.div>
                <TestimonialsSection navigationItem={navigationItem} navigateToItem={navigateToItem} />
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} viewport={{ once: true }} className='main-mobile-section-underline'></motion.div>
                <ContactUs navigationItem={navigationItem} navigateToItem={navigateToItem} />
            </div>
            <Navigation
                navigationItem={navigationItem}
                navigateToItem={navigateToItem}
            />
        </div>
    )
}

export default Main