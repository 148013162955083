import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import Logo from "../assets/img/JobForm/Logo.png";
import NoPhoto from "../assets/img/JobForm/NoPhoto.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const matchingRadioFields = {
    activity: {
        backend: "Backend",
        frontend: "Frontend",
        devOps: "DevOps",
        fullStack: "Full Stack",
    },
    experience: { "1<": "Less than a year", "1-2": "1-2 years", "3>": "3 years and more" },
    workPlace: { home: "Home", office: "Office", any: "Doesn`t matter" },
};

const matchingQuestionNames = {
    fullName: "Full Name",
    email: "Email",
    phoneNumber: "Phone Number",
    address: "Address",
    dateOfBirth: "Date of Birth",
    academicDegree: "Academic Degree",
    photo: "Photo",
    activity: "Field of expertise",
    hobbies: "Hobbies",
    experience: "Experience in development",
    socialMedia: "Link to social media",
    workPlace: "Preffered place to work",
    salary: "Preffered salary (USD)",
    cv: "CV",
};

const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
};

export const newPdg = async (formData) => {
    const answers = Object.entries(formData).map(([key, value], idx) => {
        if (key === "photo" || key === "cv") {
            return [{ text: idx + 1, alignment: "center" }, { text: matchingQuestionNames[key] }, { text: value ? "* Attached" : "* Not attached" }];
        }
        if (matchingRadioFields[key] && matchingRadioFields[key][value]) {
            return [{ text: idx + 1, alignment: "center" }, { text: matchingQuestionNames[key] }, { text: matchingRadioFields[key][value] }];
        }
        return [{ text: idx + 1, alignment: "center" }, { text: matchingQuestionNames[key] }, { text: value || "-" }];
    });

    let docDefinition = {
        content: [
            {
                columns: [
                    {
                        image: Logo,
                        width: 80,
                    },
                    {
                        stack: ["SP", "LUTSK"],
                        bold: true,
                        margin: [0, 27, 0, 0],
                    },
                ],
                absolutePosition: { y: 10, x: 50 },
                columnGap: 10,
                style: "logo",
            },
            {
                text: "CONFIDENTIAL",
                style: "confidential",
                absolutePosition: { y: 40, x: 445 },
            },
            { text: "JOB REQUEST", style: "header" },
            {
                columns: [
                    {
                        image: formData.photo ? await toBase64(formData.photo[0]) : NoPhoto,
                        width: 100,
                        quality: 0.1,
                    },
                    {
                        stack: [
                            formData.fullName,
                            matchingRadioFields.activity[formData.activity] + " developer",
                            Math.floor((new Date() - new Date(formData.dateOfBirth)) / (1000 * 60 * 60 * 24 * 365)) + " years old",
                        ],
                        // margin: [0, 30, 0, 0],
                    },
                ],
                style: "profilePreview",
                columnGap: 10,
            },
            {
                style: "answersTable",
                table: {
                    widths: [40, "30%", "*"],
                    body: [
                        [
                            { text: "№", alignment: "center" },
                            { text: "Question", alignment: "center" },
                            { text: "Answer", alignment: "center" },
                        ],
                        ...answers,
                    ],
                },
            },
            {
                text: "* All attached files are located in the Google Drive folder with this document.",
                fontSize: 10,
            },
        ],
        styles: {
            logo: {
                alignment: "left",
            },
            confidential: {
                fontSize: 15,
                bold: true,
                color: "red",
            },
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 50, 0, 10],
                alignment: "center",
            },
            profilePreview: {
                margin: [0, 20, 0, 20],
            },
            answersTable: {
                margin: [0, 5, 0, 15],
                alignment: "justify",
            },
            tableOpacityExample: {
                margin: [0, 5, 0, 15],
                fillColor: "blue",
                fillOpacity: 0.3,
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
            },
        },
        defaultStyle: {
            // alignment: 'justify'
        },
        patterns: {
            stripe45d: {
                boundingBox: [1, 1, 4, 4],
                xStep: 3,
                yStep: 3,
                pattern: "1 w 0 1 m 4 5 l s 2 0 m 5 3 l s",
            },
        },
    };

    return new Promise((resolve) => {
        pdfMake.createPdf(docDefinition).getBlob((data) => {
            resolve(data);
        });
    });
};