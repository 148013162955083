import React from 'react'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useViewport from '../hooks/useViewport';

import HamburgerMenu from './HamburgerMenu'

import Logo from '../assets/img/Common/Logo.svg'

const Header = ({ navigationItem, navigateToItem }) => {
    const navigate = useNavigate()

    const { isMobile } = useViewport()
    const { t } = useTranslation()

    return (
        <header className='header-wrapper'>
            <motion.div
                initial={{ opacity: 0, translateX: isMobile - 100 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='header-logo-wrapper'
            >
                <img className='header-logo-icon' src={Logo} alt="" />
                <h1 className='logo-project-text'>
                    10+
                    <br />
                    {t("projects")}
                </h1>
            </motion.div>
            <motion.button
                initial={{ opacity: 0, translateX: 100 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                onClick={() => navigate('/job')}
                className='header-contact-us-btn'
            >
                {t("joinOurTeam")}
            </motion.button>
            <HamburgerMenu navigationItem={navigationItem} navigateToItem={navigateToItem} />
        </header>
    )
}

export default Header