import React from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import useViewport from '../hooks/useViewport'

import Logo from '../assets/img/Common/Logo.svg'

const PrivacyPolicy = () => {
    const { isMobile } = useViewport()

    const navigate = useNavigate()

    const handleNavigateToContactUs = () => {
        navigate('/?navigateTo=Contacts')
    }

    return (
        <section className='privacy-policy-wrapper'>
            <div className='privacy-policy-header'>
                <motion.div
                    initial={{ opacity: 0, translateX: isMobile - 100 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='header-logo-wrapper'
                >
                    <img className='header-logo-icon' src={Logo} alt="" />
                    <h2 className='logo-project-text'>
                        SP-Lutsk
                        <br />
                        Chrome Extension Privacy Policy
                    </h2>
                </motion.div>
                <motion.button
                    initial={{ opacity: 0, translateX: 100 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    onClick={handleNavigateToContactUs}
                    className='header-contact-us-btn privacy'
                >
                    Contact Us
                </motion.button>
            </div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='privacy-policy-content'
            >
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        1. Introduction
                    </h5>
                    <p className='privacy-policy-section-text'>
                        This Privacy Policy describes how SP-Lutsk LLC ("we", "us", "our") collects, uses, and protects information obtained from users ("you", "your") of our Chrome extension ("the Extension"). We are committed to protecting your privacy and ensuring compliance with applicable data protection laws, including but not limited to the General Data Protection Regulation (GDPR).
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        2. Information We Collect
                    </h5>
                    <p className='privacy-policy-section-text'>
                        We do not collect any personally identifiable information ("PII") or sensitive data from users through the Extension. Our Extension operates on a "zero-knowledge" policy, meaning we do not have access to your browsing history, personal details, or any other private information.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        3. Use of Information
                    </h5>
                    <p className='privacy-policy-section-text'>
                        Since we do not collect any personal information, we do not use such information for any purpose. The Extension solely functions to provide its intended features and does not track or monitor users' activities.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        4. Third-Party Sharing
                    </h5>
                    <p className='privacy-policy-section-text'>
                        We do not share any information collected from the Extension with third parties. Your privacy is of utmost importance to us, and we uphold strict confidentiality standards.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        5. Compliance with GDPR
                    </h5>
                    <p className='privacy-policy-section-text'>
                        We are committed to complying with the provisions set forth in the General Data Protection Regulation (GDPR) and other applicable data protection laws. As such, we ensure that any data processing activities carried out in relation to the Extension are lawful, fair, and transparent.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        6. Data Security
                    </h5>
                    <p className='privacy-policy-section-text'>
                        While we do not collect any personal information, we employ appropriate security measures to protect the Extension and any data associated with it. These measures include encryption, access controls, and regular security assessments.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        7. Children's Privacy
                    </h5>
                    <p className='privacy-policy-section-text'>
                        The Extension is not intended for use by individuals under the age of 18. We do not knowingly collect any personal information from children. If you believe that a child has provided us with personal information, please contact us immediately, and we will take appropriate action to delete such information from our systems.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        8. Changes to This Privacy Policy
                    </h5>
                    <p className='privacy-policy-section-text'>
                        We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on this page. We encourage you to review this Privacy Policy periodically for any updates.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        9. Contact Us
                    </h5>
                    <p className='privacy-policy-section-text'>
                        If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at [insert contact email or other relevant contact information].
                    </p>
                    <p className='privacy-policy-section-text'>
                        By using the Extension, you consent to the terms of this Privacy Policy.
                    </p>
                    <p className='privacy-policy-section-text'>
                        Last Updated: 18.03.2024
                    </p>
                    <p className='privacy-policy-section-text'>
                        SP-Lutsk LLC is the data controller responsible for the Extension and its compliance with data protection laws.
                    </p>
                </div>
            </motion.div>
        </section>
    )
}

export default PrivacyPolicy