import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import HamburgerMenu from './HamburgerMenu'

import useAppState from '../state/useAppState'
import useViewport from '../hooks/useViewport'

const TechnologiesSection = ({ navigationItem, navigateToItem }) => {
    const { technologies } = useAppState()
    const { isMobile } = useViewport()
    const { t } = useTranslation()

    return (
        <section
            data-block={t("technologies")}
            className='technologies-section-wrapper'
        >
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='technologies-section-header'
            >
                {isMobile ? (
                    <>
                        <div className='w-full flex justify-between items-center'>
                            <h5 className='technologies-title'>{t("technologies")}</h5>
                            <HamburgerMenu navigationItem={navigationItem} navigateToItem={navigateToItem} />
                        </div>
                        <h6 className='technologies-subtitle mt-6 lg:mt-8'>Backend</h6>
                    </>
                ) : (
                    <>
                        <h5 className='technologies-title'>{t("technologies")}</h5>
                        <h6 className='technologies-subtitle'>Backend</h6>
                    </>
                )}
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='technologies-list'
            >
                {technologies.backend.map((technology) => (
                    <div key={technology.id} className='technologies-item'>
                        {technology.name}
                        {technology.icon && (
                            <img width={isMobile ? 18 : 24} src={technology.icon} alt="" />
                        )}
                    </div>
                ))}
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='technologies-section-header'
            >
                <h5 className='technologies-title'> </h5>
                <h6 className='technologies-subtitle lg:mt-12'>Frontend</h6>
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='technologies-list'
            >
                {technologies.frontend.map((technology) => (
                    <div key={technology.id} className='technologies-item'>
                        {technology.name}
                        {technology.icon && (
                            <img width={isMobile ? 18 : 24} src={technology.icon} alt="" />
                        )}
                    </div>
                ))}
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='technologies-section-header'
            >
                <h5 className='technologies-title'> </h5>
                <h6 className='technologies-subtitle lg:mt-12'>DevSecOps</h6>
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='technologies-list'
            >
                {technologies.devsecops.map((technology) => (
                    <div key={technology.id} className='technologies-item'>
                        {technology.name}
                        {technology.icon && (
                            <img width={isMobile ? 18 : 24} src={technology.icon} alt="" />
                        )}
                    </div>
                ))}
            </motion.div>
        </section>
    )
}

export default TechnologiesSection