import React from 'react'
import { motion } from 'framer-motion'
import { FaCircleCheck } from 'react-icons/fa6'

const Toast = ({ setShowToast }) => {
    return (
        <motion.div class="toast success">
            <div class="container-1">
                <FaCircleCheck color={"#47D764"} />
            </div>
            <div class="container-2">
                <p>Success</p>
                <p>Your application has been sent successfully!</p>
            </div>
            <button onClick={() => setShowToast(false)} type='button'>&times;</button>
        </motion.div>
    )
}

export default Toast