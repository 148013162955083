import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import useViewport from '../hooks/useViewport'
import useAppState from '../state/useAppState'
import useSlider from '../hooks/useSlider'

import HamburgerMenu from './HamburgerMenu'

const ServicesSection = ({ navigationItem, navigateToItem }) => {
    const { t } = useTranslation()
    const { services } = useAppState()
    const { isMobile } = useViewport()
    const { currentSlide, totalSlides, setCurrentSlide, currentItems } = useSlider(services, 2)

    return (
        <section data-block={t("services")} className='services-section-wrapper'>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='w-full flex justify-between items-center'
            >
                <h5 className='services-section-header'>{t("services")}</h5>
                <HamburgerMenu navigationItem={navigationItem} navigateToItem={navigateToItem} />
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='services-section-list-block'
            >
                {currentItems.map((service) => (
                    <div key={service.id} className='service-block-item'>
                        <h6 className='service-block-item-title'>
                            {service.title}
                        </h6>
                        <p className='service-block-item-description'>
                            {service.description}
                        </p>
                    </div>
                ))}
            </motion.div>
            {!isMobile && totalSlides > 1 && (
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='projects-slides-pagination-block'
                >
                    {totalSlides.map((slide) => (
                        <div key={slide} onClick={() => setCurrentSlide(slide)} className={`project-slide-pagination-item ${currentSlide === slide ? 'active' : ''}`}></div>
                    ))}
                </motion.div>
            )}
        </section>
    )
}

export default ServicesSection