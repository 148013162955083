import React from 'react'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './Header'

const HomeSection = ({ navigationItem, navigateToItem }) => {
    const navigate = useNavigate()

    const { t } = useTranslation()

    return (
        <section data-block={t("home")} className='home-section-wrapper'>
            <Header
                navigationItem={navigationItem}
                navigateToItem={navigateToItem}
            />
            <div className='home-section-big-text-block'>
                <motion.h5
                    initial={{ opacity: 0, translateX: -200 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='home-section-design-text'
                >
                    {t("design").split('').map((letter, index) => (
                        <motion.span
                            key={index}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 0.25,
                                delay: index / 6,
                            }}
                        >
                            {letter}
                        </motion.span>
                    ))}
                </motion.h5>
                <motion.h5
                    initial={{ opacity: 0, translateX: -200 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    transition={{ duration: 0.8, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='home-section-development-text'
                >
                    {t("development").split('').map((letter, index) => (
                        <motion.span
                            key={index}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 0.25,
                                delay: index / 6,
                            }}
                        >
                            {letter}
                        </motion.span>
                    ))}
                </motion.h5>
                <motion.h5
                    initial={{ opacity: 0, translateX: -200 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='home-section-security-text'
                >
                    {t("security").split('').map((letter, index) => (
                        <motion.span
                            key={index}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 0.25,
                                delay: index / 6,
                            }}
                        >
                            {letter}
                        </motion.span>
                    ))}
                </motion.h5>
            </div>
            <div className='home-section-bottom-block'>
                <motion.span
                    initial={{ opacity: 0, translateX: -100 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='home-section-bottom-description-text'
                >
                    {t("weProvideService")}
                    <br />
                    {t("developmentOfAllTastes")}
                    <br />
                    {t("ourDorsOpen")}
                    <br />
                    {t("offers")}
                </motion.span>
                <motion.span
                    initial={{ opacity: 0, translateX: 100 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='home-section-bottom-links'
                >
                    <a href="https://www.facebook.com/SoftwareProductionLutsk" rel="noreferrer" target='_blank'>Facebook</a>
                    <a href="https://www.instagram.com/sp_lutsk?igsh=OWFtMW4zanVhYjI=" rel="noreferrer" target='_blank'>Instagram</a>
                    <a href="https://www.linkedin.com/company/sp-lutsk" rel="noreferrer" target='_blank'>Linkedin</a>
                </motion.span>
            </div>
            <div className='w-full flex justify-center'>
                <button onClick={() => navigate('/job')} className='home-section-contact-us-btn-mobile'>{t("joinOurTeam")}</button>
            </div>
            <motion.span
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='home-section-bottom-links-mobile'
            >
                <a href="https://www.facebook.com/SoftwareProductionLutsk" rel="noreferrer" target='_blank'>Facebook</a>
                <a href="https://www.instagram.com/sp_lutsk?igsh=OWFtMW4zanVhYjI=" rel="noreferrer" target='_blank'>Instagram</a>
                <a href="https://www.linkedin.com/company/sp-lutsk" rel="noreferrer" target='_blank'>Linkedin</a>
            </motion.span>
        </section>
    )
}

export default HomeSection