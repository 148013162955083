import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import { useAppContext } from '../context/AppContext'


const Navigation = ({ navigationItem, navigateToItem }) => {
    const { language, setLanguage } = useAppContext()

    const { t } = useTranslation()

    const NAVIGATION_ITEMS = [t("home"), t("technologies"), t("projectsSection"), t("services"), t("testimonials"), t("contacts")]

    const changeLanguage = () => {
        setLanguage(language === "en" ? "ua" : "en")
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, ease: 'easeInOut' }}
            viewport={{ once: true }}
            className='navigation-wrapper'
        >
            {NAVIGATION_ITEMS.map((item, index) => (
                <div key={index} onClick={() => navigateToItem(item)} className={`navigation-item ${navigationItem === item ? 'active' : ''}`}>{item}</div>
            ))}
            <div onClick={changeLanguage} className='navigation-item active'>{language === "en" ? "US" : "UA"}</div>
        </motion.div>
    )
}

export default Navigation