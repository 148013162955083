import React from 'react'
import { motion } from 'framer-motion'

const ProjectTechnologiesTree = ({ list }) => {
    const renderTree = (data) => {
        return data.map((item, index) => {
            if (Array.isArray(item)) {
                return (
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 1, ease: 'easeInOut' }}
                        viewport={{ once: true }}
                        className={`project-technology-block ${item.length === 1 ? 'ordinary' : ''}`} key={index}
                    >
                        {renderTree(item)}
                    </motion.div>
                );
            } else {
                if (item) {
                    return (
                        <motion.div
                            initial={{ opacity: 0, translateX: index === 0 ? -200 : 200 }}
                            whileInView={{ opacity: 1, translateX: 0 }}
                            transition={{ duration: 1, ease: 'easeInOut' }}
                            viewport={{ once: true }}
                            className='project-technology-item'
                            key={index}
                        >
                            <div className='project-technology-name-block'>
                                <img src={item?.left_image} alt="" />
                                <span className='project-technology-name'>{item?.name}</span>
                                <img src={item?.right_image} alt="" />
                            </div>
                            <p className='project-technology-usage'>{item?.usage}</p>
                        </motion.div>
                    );
                } else {
                    return null;
                }
            }
        });
    };

    const createTree = (list) => {
        const tree = [];
        let currentIndex = 0;

        while (currentIndex < list.length) {
            let currentLevel = [];
            const itemsInCurrentLevel = (currentIndex % 3 === 0) ? 2 : 1;

            for (let i = 0; i < itemsInCurrentLevel; i++) {
                currentLevel.push(list[currentIndex]);
                currentIndex++;
            }

            tree.push(currentLevel);
        }

        return renderTree(tree);
    };

    return (
        <>
            {createTree(list)}
        </>
    )
}

export default ProjectTechnologiesTree