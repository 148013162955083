import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import useAppState from '../state/useAppState'
import useViewport from '../hooks/useViewport'
import useSlider from '../hooks/useSlider'

import HamburgerMenu from './HamburgerMenu'

const TestimonialsSection = ({ navigationItem, navigateToItem }) => {
    const { t } = useTranslation()
    const { testimonials } = useAppState()
    const { isMobile } = useViewport()
    const { currentSlide, totalSlides, setCurrentSlide, currentItems } = useSlider(testimonials, 2)

    return (
        <section data-block={t("testimonials")} className='testimonials-section-wrapper'>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='w-full flex justify-between items-center'
            >
                <h5 className='services-section-header'>{t("testimonials")}</h5>
                <HamburgerMenu navigationItem={navigationItem} navigateToItem={navigateToItem} />
            </motion.div>
            <div className='testimonials-section-list-block'>
                {currentItems.map((testimonial) => (
                    <motion.div
                        initial={{ opacity: 0, translateX: isMobile ? 0 : -100 }}
                        whileInView={{ opacity: 1, translateX: 0 }}
                        transition={{ duration: 1, ease: 'easeInOut' }}
                        viewport={{ once: true }}
                        key={testimonial.id}
                        className='testimonial-block-item'
                    >
                        <div className='testimonial-block-item-heading'>
                            <h6 className='testimonial-block-item-name'>{testimonial.name}</h6>
                            <div className='testimonial-block-item-vertical-line'></div>
                            <h6 className='testimonial-block-item-name'>{testimonial.project}</h6>
                        </div>
                        <p className='testimonial-block-item-text'>
                            {testimonial.text}
                        </p>
                    </motion.div>
                ))}
            </div>
            {!isMobile && totalSlides > 1 && (
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='projects-slides-pagination-block'
                >
                    {totalSlides.map((slide) => (
                        <div key={slide} onClick={() => setCurrentSlide(slide)} className={`project-slide-pagination-item ${currentSlide === slide ? 'active' : ''}`}></div>
                    ))}
                </motion.div>
            )}
        </section>
    )
}

export default TestimonialsSection