import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import useAppState from '../state/useAppState'
import useViewport from '../hooks/useViewport'
import useSlider from '../hooks/useSlider'

import HamburgerMenu from './HamburgerMenu'

import ArrowRight from '../assets/img/Common/ArrowRight.svg'
import Nda from '../assets/img/Common/Nda.svg'

const ProjectsSection = ({ navigationItem, navigateToItem }) => {
    const { projects } = useAppState()
    const { isMobile } = useViewport()
    const { currentSlide, totalSlides, setCurrentSlide, currentItems } = useSlider(projects, 3)
    const { t } = useTranslation()

    return (
        <section data-block={t("projectsSection")} className='projects-section-wrapper'>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='projects-section-header'
            >
                {isMobile ? (
                    <>
                        <div className='w-full flex justify-between items-center'>
                            <h5 className='technologies-title'>{t("projectsSection")}</h5>
                            <HamburgerMenu navigationItem={navigationItem} navigateToItem={navigateToItem} />
                        </div>
                        <h6 className='technologies-subtitle mt-6 lg:mt-2'>{t("webDevelopment")}</h6>
                    </>
                ) : (
                    <>
                        <h5 className='projects-section-title'>{t("projectsSection")}</h5>
                        <h6 className='projects-section-subtitle'>
                            {t("webDevelopment")}
                        </h6>
                    </>
                )}
            </motion.div>
            <div className='projects-section-list'>
                {currentItems.map((project) => (
                    <motion.div
                        initial={{ opacity: 0, translateX: isMobile ? 0 : -200 }}
                        whileInView={{ opacity: 1, translateX: 0 }}
                        transition={{ duration: 1, ease: 'easeInOut' }}
                        viewport={{ once: true }}
                        key={project.id}
                        className='project-item-block'
                    >
                        {isMobile ? (
                            <img className='project-item-image' src={project.mobileImage} alt="" />
                        ) : (
                            <img className='project-item-image' src={project.image} alt="" />
                        )}
                        {project.nda && (
                            <span className='project-item-nda'>
                                <img width={isMobile ? 130 : 140} src={Nda} alt="" />
                            </span>
                        )}
                        <Link to={`/project/${project.id}`}>
                            {!isMobile && (project?.name === 'OurPublicRecords' || project?.name === 'AI Excel Bot') ? (
                                <button className='project-item-learn-more-btn-white'>
                                    {t("learnMore")}
                                    {!isMobile && (
                                        <img src={ArrowRight} alt="" />
                                    )}
                                </button>
                            ) : (
                                <button className='project-item-learn-more-btn'>
                                    {t("learnMore")}
                                    {!isMobile && (
                                        <img src={ArrowRight} alt="" />
                                    )}
                                </button>
                            )}
                        </Link>
                    </motion.div>
                ))}
            </div>
            {!isMobile && (
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='projects-slides-pagination-block'
                >
                    {totalSlides.map((slide) => (
                        <div key={slide} onClick={() => setCurrentSlide(slide)} className={`project-slide-pagination-item ${currentSlide === slide ? 'active' : ''}`}></div>
                    ))}
                </motion.div>
            )}
        </section >
    )
}

export default ProjectsSection