import { useEffect, useState } from 'react'
import useViewport from './useViewport'

const useSlider = (items, showPerSlide) => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const [totalSlides, setTotalSlides] = useState([])
    const [currentItems, setCurrentItems] = useState([])

    const { isMobile } = useViewport()

    useEffect(() => {
        if (isMobile) {
            setCurrentItems(items)
        } else {
            setCurrentItems(items.slice(currentSlide * showPerSlide, currentSlide * showPerSlide + showPerSlide))
        }
    }, [currentSlide, items, showPerSlide, isMobile])

    useEffect(() => {
        if (isMobile) return

        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % totalSlides.length)
        }, 35000)

        return () => clearInterval(interval)
    }, [totalSlides, isMobile])

    useEffect(() => {
        const slidesIndexes = Array.from({ length: Math.ceil(items.length / showPerSlide) }, (_, i) => i)
        setTotalSlides(slidesIndexes)
    }, [items, showPerSlide])

    return {
        currentSlide,
        totalSlides,
        setCurrentSlide,
        currentItems
    }
}

export default useSlider