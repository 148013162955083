import { useTheme } from './ThemeProvider';
import React, { useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import InstagramPrivacyPolicy from './page/InstagramPrivacyPolicy';
import PrivacyPolicy from './page/PrivacyPolicy';
import Project from './page/Project';
import Main from './page/Main';
import Layout from './Layout';
import Job from './page/Job';

import './App.css';

const App = () => {
  const { isDarkMode } = useTheme();
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <main className={`${isDarkMode ? 'bg-night' : 'bg-white'}`}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Main />} />
          <Route path='project/:id' element={<Project />} />
          <Route path='/chrome-extension-privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/instagram-privacy-policy' element={<InstagramPrivacyPolicy />} />
          <Route path='job' element={<Job />} />
        </Route>
      </Routes>
    </main>
  );
};

export default App;
