import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { AppProvider } from './context/AppContext';
import { ThemeProvider } from './ThemeProvider';

import App from './App';

import './index.css';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <AppProvider>
          <Routes>
            <Route path="/*" element={<ThemeProvider><App /> </ThemeProvider>} />
          </Routes>
        </AppProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
