import React from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import useViewport from '../hooks/useViewport'

import Logo from '../assets/img/Common/Logo.svg'

const InstagramPrivacyPolicy = () => {
    const { isMobile } = useViewport()

    const navigate = useNavigate()

    const handleNavigateToContactUs = () => {
        navigate('/?navigateTo=Contacts')
    }

    return (
        <section className='privacy-policy-wrapper'>
            <div className='privacy-policy-header'>
                <motion.div
                    initial={{ opacity: 0, translateX: isMobile - 100 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    className='header-logo-wrapper'
                >
                    <img className='header-logo-icon' src={Logo} alt="" />
                    <h2 className='logo-project-text'>
                        SP-Lutsk
                        <br />
                        Instagram Privacy Policy
                    </h2>
                </motion.div>
                <motion.button
                    initial={{ opacity: 0, translateX: 100 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                    onClick={handleNavigateToContactUs}
                    className='header-contact-us-btn privacy'
                >
                    Contact Us
                </motion.button>
            </div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
                viewport={{ once: true }}
                className='privacy-policy-content'
            >
                <div className='privacy-policy-section'>
                    <p className='privacy-policy-section-text'>
                        At SP-Lutsk, we are committed to maintaining the privacy and security of your information. This Privacy Policy outlines how we collect, use, and disclose information when you use our software that monitors comments on our Instagram page and sends notifications to our work chat. Please read this policy carefully to understand our practices regarding your information and how we will treat it.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        1. Information We Collect
                    </h5>
                    <p className='privacy-policy-section-text'>
                        When you use our software, we may collect the following types of information:
                    </p>
                    <p className='privacy-policy-section-text'>
                        <ul>
                            <li>Instagram Comments: Our software monitors comments on our Instagram page to provide notifications to our team.</li>
                            <li>User ID: We may collect your user ID associated with your Instagram account in order to provide the service.</li>
                        </ul>
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        2. How We Use Your Information
                    </h5>
                    <p className='privacy-policy-section-text'>
                        We may use the information we collect for the following purposes:
                    </p>
                    <p className='privacy-policy-section-text'>
                        <ul>
                            <li>Providing Services: To monitor comments on our Instagram page and send notifications to our work chat.</li>
                        </ul>
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        3. Information Sharing
                    </h5>
                    <p className='privacy-policy-section-text'>
                        We do not share your information with any third parties under any circumstances.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        4. Data Security
                    </h5>
                    <p className='privacy-policy-section-text'>
                        We employ appropriate security measures to safeguard your information against unauthorized access, alteration, disclosure, or destruction.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        5. Data Retention
                    </h5>
                    <p className='privacy-policy-section-text'>
                        We retain your information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        6. Your Rights
                    </h5>
                    <p className='privacy-policy-section-text'>
                        You have the right to access, correct, or delete your information at any time. If you have any questions or requests regarding your information, please contact us using the provided contact information.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        7. Changes to this Privacy Policy
                    </h5>
                    <p className='privacy-policy-section-text'>
                        We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated Privacy Policy on this page.
                    </p>
                </div>
                <div className='privacy-policy-section'>
                    <h5 className='privacy-policy-section-title'>
                        8. Contact Us
                    </h5>
                    <p className='privacy-policy-section-text'>
                        If you have any questions, concerns, or complaints about our Privacy Policy or our practices regarding your information, please contact us at <a style={{ color: 'blue' }} href="mailto:inbox@sp-lutsk.com">inbox@sp-lutsk.com.</a>
                    </p>
                    <p className='privacy-policy-section-text'>
                        By using our software, you agree to the terms of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please refrain from using our software.
                    </p>
                </div>
            </motion.div>
        </section>
    )
}

export default InstagramPrivacyPolicy